import React, { useEffect, useRef, useState } from 'react';
import VideoPlayerControls from './VideoPlayerControls';

function VideoPlayer({ Video, height, width = '100%' }) {
  const [isPaused, setIsPaused] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const [videoProgress, setVideoProgress] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      setVideoDuration(video.duration);
    }
  }, []);

  useEffect(() => {
    if (isPaused) return;
    const currentTime = videoRef.current?.currentTime

    if (videoDuration != null & currentTime != null) {
      let loadingTimeout = setTimeout(() => {
        if (videoProgress == currentTime / videoDuration) {
          setVideoProgress((prev) => prev + 0.000001);
        } else {
          if (!videoDuration) {
            setVideoDuration(videoRef.current?.duration)
          }
          setVideoProgress(currentTime / videoDuration)
        }
      }, 10);

      return () => {
        clearTimeout(loadingTimeout);
      };
    }

  }, [videoProgress, videoDuration, isPaused]);


  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      setIsPaused(!video.paused);
      video.paused ? video.play() : video.pause();
    }
  }

  if (height) {
    return (
      <div className={`relative w-[100%] mx-auto my-8 rounded-xl overflow-hidden h-[${height}] sm:max-h-[700px]`}>
        <div className='absolute top-4 right-4 z-10'>
          <VideoPlayerControls progress={videoProgress} isPaused={isPaused} onPlayPause={togglePlayPause} />
        </div>
        <video ref={videoRef} className='w-full aspect-auto' loop autoPlay playsInline>
          <source src={Video} type='video/mp4'></source>
        </video>
      </div>
    )
  }

  return (
    <div className={`relative w-[95%] sm:w-[50%] mx-auto my-8 rounded-xl overflow-hidden`}>
      <div className='absolute top-4 right-4 z-10'>
        <VideoPlayerControls progress={videoProgress} isPaused={isPaused} onPlayPause={togglePlayPause} />
      </div>
      <video ref={videoRef} className='w-full' loop autoPlay playsInline>
        <source src={Video} type='video/mp4'></source>
      </video>
    </div>
  )
}

export default VideoPlayer
