// src/context/LoadingContext.js
import React, { createContext, useState, useContext } from "react";

// Create the context
const LoadingContext = createContext();

// Export a custom hook for accessing the loading state
export const useLoading = () => useContext(LoadingContext);

// Loading provider to wrap your app
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
