import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useContentful } from './contentful-context';
import StoryText from './StoryText';

const images = [
  <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/maven-trusses.jpg'} className='h-[600px] mx-auto rounded-lg' />,
  <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/maven-wall.jpg'} className='h-[600px] mx-auto rounded-lg' />,
  <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/nelson-inside.jpg'} className='h-[600px] mx-auto rounded-lg' />,

]

function StoryTextStart({ title, subtitle, body,  }) {
  return (
      <div className='bg-white w-full text-start flex flex-col justify-center gap-3 sm:p-0'>
          {/* <Animate> */}
              {title && (<h1 className={`md:text-3xl text-xl font-semibold text-start sm:text-start`}>{title}</h1>)}
          {/* </Animate> */}
          {/* <Animate> */}
              {subtitle && (<p className={`md:text-3xl text-2xl text-[#a43424] md:text-center text-justify`}>{subtitle}</p>)}
          {/* </Animate> */}
          <h2
              className={`md:py-3 text-gray-600 text-justify ${title ? 'md:text-xl text-base' : 'md:text-4xl sm:text-2xl text-2xl'}`}
          >
              {body}
          </h2>
      </div>
  )
}

function TextImageBlock({ title, body }) {
  const [imageIndex, setImageIndex] = useState(0);
  const navigate = useNavigate();
  const content = useContentful();

  const handleClick = location => navigate(location);


  useEffect(() => {
    let imageTimeout = setTimeout(() => {
        nextImage();
    }, 3000)

    return () => {
        clearTimeout(imageTimeout);
    };
  })

  const openMatterport = () => {
    window.open('https://my.matterport.com/show/?m=ydhoJPRhwGE', '_blank');
  }

  if(!content.images.length){
    return null;
  }

  const nextImage = () => {
    const isFirstImage = imageIndex === 0;
    const nextImage = isFirstImage ? images.length -1 : imageIndex -1;
    setImageIndex(nextImage)
  }


  const slideImages = content.images.find(images => images.id == 'home-slider-images');
  const images = slideImages.images;
  const imageUrls = images.map((image, i) => image.fields.file.url);

  return (
    <div className='w-full bg-white'>
    <div className='max-w-[1240] md:flex flex-row justify-between items-stretch'>
      <div className='flex flex-col justify-top sm:w-[45%]'>
          <StoryText
            title={title}
            body={body}
          />
          {/* <h1 className='md:text-4xl text-2xl font-semibold py-2 text-start text-black'>{title}</h1>
          <p className='my-4 text-slate-500 text-justify md:w-[800px] md:text-lg text-lg'>
              {body}
          </p> */}
          {/* <button onClick={openMatterport} className='bg-black text-white w-[250px] rounded font-medium my-6 mx-auto md:mx-0 py-3'>Tour Our Building System </button> */}
      </div>
      <div>
        <iframe className='sm:w-[830px] w-[100%] mt-10' width="830" height="480" src='https://my.matterport.com/show/?m=ydhoJPRhwGE' frameBorder={0} allowFullScreen allow='xr-spacial-tracking'></iframe>
      </div>
    </div>
  </div>
  )
}

export default TextImageBlock
