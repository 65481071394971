import React from 'react';
import { Helmet } from "react-helmet";



const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-10 sm:p-20">
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <p>
                At Build Construx, Inc., we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">1. Information We Collect</h2>
            <p>
                We may collect personal information such as your name, email address, and any other details you provide when interacting with our website. We also collect non-personal information such as browser type, IP address, and usage data to improve the Service.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">2. How We Use Your Information</h2>
            <p>
                We use the information we collect to operate, maintain, and improve our Service, as well as to respond to your inquiries and provide customer support. We may also use your information to send you updates and marketing communications if you have opted-in to receive them.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">3. Sharing of Information</h2>
            <p>
                We do not sell or share your personal information with third parties except to comply with legal obligations, protect our rights, or provide necessary services (e.g., third-party service providers helping to maintain our platform).
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">4. Data Security</h2>
            <p>
                We implement appropriate security measures to protect your personal information from unauthorized access and disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee its absolute security.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">5. Your Rights</h2>
            <p>
                You have the right to access, update, or delete your personal information at any time by contacting us. You may also unsubscribe from marketing communications by following the unsubscribe instructions included in those communications.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">6. Changes to This Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this page periodically.
            </p>

            <p className="mt-6">If you have any questions about this Privacy Policy, please contact us at privacy@buildconstrux.com.</p>
        </div>
    );
};

export default PrivacyPolicy;
