import React from 'react'
import { InView } from 'react-intersection-observer'

function Animate({ children }) {
  return (
    <InView>
        {({ inView, ref, entry }) => (
            <div ref={ref} className='text-center'>
                <div className={`${inView ? 'opacity-100 translate-y-1 backdrop-blur-[12px] duration-500' : 'opacity-0 translate-y-[-90%] backdrop-blur-none text-opacity-0'}`}>
                    {children}
                </div>
            </div>
        )}
    </InView>
  )
}

export default Animate
