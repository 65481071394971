import React from 'react'
import Animate from './Animate'
import VideoPlayer from './VideoPlayer/VideoPlayer'

function TextMediaBlockVariant({ Video, title, body, button, animate }) {

  if(animate){
    return (
        <div className='w-full bg-white'>
            <div className='max-w-[1240] mx-auto md:grid md:grid-cols-2 flex flex-col-reverse'>
                <div className='flex flex-col justify-top md:px-10 py-10'>
                    <Animate>
                        <h1 className='md:text-3xl text-xl font-semibold text-start md:text-center'>{title}</h1>
                        <p className='text-black py-6 text-justify text-base sm:text-xl'>
                            {body}
                        </p>
                    </Animate>
                    {button && (<button className='bg-black text-white w-[200px] rounded font-medium my-6 mx-auto md:mx-0 py-3'>Learn about the system</button>)}
                </div>
                <VideoPlayer Video={Video} height={true} />
            </div>
        </div>
    )
  }
  return (
    <div className='w-full bg-white py-10 px-4'>
        <div className='max-w-[1240] mx-auto md:grid md:grid-cols-2 flex flex-col-reverse'>
        <div className='flex flex-col justify-top md:px-10 py-10'>
            <h1 className='md:text-3xl sm:text-2xl text-xl font-semibold md:w-[300px] sm:text-start text-center'>{title}</h1>
            <p className='text-gray-400 py-6 text-center md:text-start md:text-lg'>
                {body}
            </p>
            {button && (<button className='bg-black text-white w-[200px] rounded font-medium my-6 mx-auto md:mx-0 py-3'>Learn about the system</button>)}
        </div>
        <VideoPlayer Video={Video}/>
        </div>
    </div>
  )
}

export default TextMediaBlockVariant
