import React from 'react';
import TextBlock from '../TextBlock';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ComponentFeatures from '../ComponentFeatures';
import TextMediaBlock from '../TextMediaBlock';
import TextMediaBlockVariant from '../TextMediaBlockVariant';
import StoryText from '../StoryText';
import Animate from '../Animate';
import { useContentful } from '../contentful-context';
import { getImageUrlByName } from '../utils';
import AssemblyVideo from '../../assets/assembly-video.mp4';
import PileFoundationImage from '../../assets/maven-completed-wall.jpg';
import { Helmet } from "react-helmet";


import CompletedNelsonImage from '../../assets/completed-nelson.png';
import ConstruxModelImage from '../../assets/construx-modeling.png';
import { Link } from 'react-router-dom';

import { Carousel } from '../ComponentFeatures';

function Product() {
  const content = useContentful();

  if (!content.product.length) {
    return null;
  }

  const first = content.product.find(item => item.sectionId.indexOf('1') != -1);
  const second = content.product.find(item => item.sectionId.indexOf('2') != -1);
  const third = content.product.find(item => item.sectionId.indexOf('3') != -1);
  const fourth = content.product.find(item => item.sectionId.indexOf('4') != -1);
  const fifth = content.product.find(item => item.sectionId.indexOf('5') != -1);
  const sixth = content.product.find(item => item.sectionId.indexOf('6') != -1);

  const finishedFrameImageUrl = getImageUrlByName(content.images, 'frame-complete');
  const finishedNelsonImageUrl = getImageUrlByName(content.images, 'finished-nelson');
  const completedNelsonImageUrl = getImageUrlByName(content.images, 'complete-nelson');
  const frameOnPiles = getImageUrlByName(content.images, 'frame-on-piles');
  const frameOnSlab = getImageUrlByName(content.images, 'frame-on-slab');


  return (
    <div className='bg-white px-10 md:px-20 font-bai'>
       <Helmet>
        <title>Product</title>
      </Helmet>
        <div className='flex flex-row'>
        <VideoPlayer Video={AssemblyVideo} />
      </div>
        <StoryText
          title={first.header}
          body={first.section}
        />

        <div className='w-full bg-white flex flex-col flex-wrap py-10'>
          <h1 className='md:text-3xl text-xl font-semibold text-start md:text-center'>Compatible with any foundation</h1>
          <div className='flex flex-row justify-around flex-wrap text-center'>
            <div>
              <img src={PileFoundationImage} className='sm:max-w-[1150px] sm:max-h-[1000px] mt-2 py-5 md:p-10'/>
              <p className='md:text-3xl text-xl  font-semibold text-start md:text-center'>Crawlspace or Pier & Beam</p>
            </div>
            <div>
              <img src={frameOnSlab} className='sm:max-w-[1000px] sm:max-h-[1000px] mt-2 py-5 md:p-10' />
              <p className='md:text-3xl text-xl  font-semibold text-start md:text-center'>Slab or Basement</p>
            </div>
          </div>
        </div>

      <ComponentFeatures />
      <TextMediaBlock
        title={second.header}
        body={second.section}
        Video={second.video.fields.file.url}
        button={false}
        animate
      />
      <TextMediaBlockVariant
        title={third.header}
        body={third.section}
        Video={third.video.fields.file.url}
        button={false}
        animate
      />
      <TextMediaBlock
        title={fourth.header}
        body={fourth.section}
        Video={fourth.video.fields.file.url}
        button={false}
        animate
      />
      <TextMediaBlockVariant
        title={fifth.header}
        body={fifth.section}
        Video={fifth.video.fields.file.url}
        button={false}
        animate
      />
      <Animate>
        <div className='w-full bg-white flex flex-row flex-wrap justify-around'>
          {/* <img src={sixth.imageUrl} /> */}
          <div className='flex flex-col justify-top py-10'>
            <h1 className='md:text-3xl sm:text-2xl text-xl font-semibold text-start md:text-center text-black'>
              {sixth.header}
            </h1>
            <p className='text-black py-6  md:text-lg text-justify'>
              {sixth.section}
            </p>
          </div>
        </div>
      </Animate>
        <div className='w-full = bg-white flex flex-row flex-wrap justify-around'>
          {/* <img src={finishedNelsonImageUrl} className='sm:w-[600px] sm:h-[400px] mt-2'/> */}
          {/* <img src={completedNelsonImageUrl} className='sm:w-[600px] sm:h-[400px] mt-2' /> */}
        </div>
        <Carousel />
      <Link to='/models'>
          <h1 className='p-20 hover:cursor-pointer underline sm:text-lg text-xl font-semibold text-center md:text-center text-black'>
            View more models
          </h1>
      </Link>

    </div>
  )
}

export default Product
