// src/components/LoadingScreen.js
import React from "react";
import { useLoading } from "../../context/LoadingContext";
import "./LoadingScreen.css"; // Import the CSS for styling

const LoadingScreen = () => {
  const { loading } = useLoading();

  if (!loading) return null;

  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-75">
      <div className="spinner"></div> {/* Spinner element */}
      <div className="text-white text-3xl font-bold mt-4">Loading...</div>
    </div>
  );
};

export default LoadingScreen;
