import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useContentful } from "./contentful-context";
import { Helmet } from "react-helmet";


const FAQ = () => {
  const content = useContentful();

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  console.log(content)

  var faqs = content.faqs;

  if (!content.faqs.length) {
    return null;
  }


  function sortByKey(array) {
    return array.sort((a, b) => {
      if (parseInt(a.sortkey) < parseInt(b.sortkey)) {
        return -1;
      }
      if (parseInt(a.sortkey) > parseInt(b.sortkey)) {
        return 1;
      }
      return 0;
    });
  }


  var sortedFaqs = sortByKey(faqs)


  return (
    <div className="max-w-4xl mx-auto min-h-full bg-white py-20 px-10 font-bai">
      <Helmet>
        <title>FAQs</title>
      </Helmet>
      <h2 className="text-2xl font-bold mb-6 text-center">FAQ</h2>
      {sortedFaqs.map((faq, index) => (
        <div key={index} className="border-b">
          <div
            className="flex justify-between items-center py-4 cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <h2 className="text-xl font-semibold">{faq.question}</h2>
            <span>
              {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          <div
            className={`transition-[max-height] ease-in-out duration-300 overflow-hidden ${activeIndex === index ? 'max-h-100' : 'max-h-0'
              }`}
          >
            <div
              className={`transition-opacity duration-500 ease-in-out ${activeIndex === index ? 'opacity-100' : 'opacity-0'
                }`}
            >
              <div className="pb-4 text-gray-600">
                {faq.answers}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
