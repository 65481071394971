import React from 'react';
import Animate from './Animate';
import Viewer from './Viewer/Viewer';
import { useContentful } from './contentful-context';
import { getImageUrlByName } from './utils';
import StoryText from './StoryText';

import ConstruxComponentImage from '../assets/construx-component.png'
import ConstruxComponentsImage from '../assets/construx-components.png'
import SampleWallBoxImage from '../assets/SampleWallBox.png';
import SampleWallBoxColorImage from '../assets/SampleWallBoxColor.png';
import BeamNestImage from '../assets/BeamNest.png';
import ConstruxStructureImage from '../assets/construx-structure.png';
import ConstruxEnvelopeImage from '../assets/construx-envelope.jpg';
import ConstruxCompleteSideImage from '../assets/construx-complete-side.png';
import ShippingModelImage from '../assets/shipping-model.png';
import ComponentAssemblyImage from '../assets/component-assembly.png';
import CompletedNelsonImage from '../assets/completed-nelson.png';
import ConstruxModelImage from '../assets/construx-modeling.png';


export function Carousel() {
  return (
    <div className='w-full bg-white flex flex-col justify-between align-baseline md:flex-row mt-10 mb-2 sm:w-[100%] overflow-scroll'>
      {/* <img src={SampleWallBoxImage} className='px-30 w-[80%] mt-10 sm:mt-0 rounded-xl mx-auto' /> */}
      {/* <img src={SampleWallBoxColorImage} className='px-30 w-[85%] mt-10 sm:mt-0 rounded-xl mx-auto' /> */}
      <img src={ConstruxStructureImage} className='w-[85%] sm:w-[55%] sm:mt-0 rounded-xl mx-auto aspect-auto p-1' />
      <img src={ConstruxCompleteSideImage} className='w-[85%] sm:w-[55%] sm:mt-0 rounded-xl mx-auto aspect-auto p-1' />
      {/* <img src={ConstruxEnvelopeImage} className='w-[85%] sm:w-[65%] sm:mt-0 rounded-xl mx-auto aspect-auto p-1' /> */}
      <img src={CompletedNelsonImage} className='w-[85%] sm:w-[65%] sm:mt-0 rounded-xl mx-auto aspect-auto p-1' />
    </div>
  )
}


function Feature({ title, body }) {
  return (
    <div className='py-2 p-2 mt-'>
      <h2 className='text-base font-semibold text-start text-black'>
        {title}
      </h2>
      <Animate>
        <p className='text-justify w-[100%] sm:w-[30rem] text-gray-600 py-2 font-sans text-xs md:text-base'>
          {body}
        </p>
      </Animate>
    </div>
  )
}

function ComponentFeatures() {
  const content = useContentful();

  if (!content.features.length || !content.images.length) {
    return null;
  }

  const componentImage = getImageUrlByName(content.images, 'shop-demo');

  return (
    <div>
      <div className='w-full bg-white flex flex-col justify-start md:flex-row sm:w-[100%]'>
        <div className='flex flex-row'>
          <div>
            <div className='flex flex-col w-0 sm:w-[100%] justify-start'>
              {/* <img src={BeamNestImage} className='w-[100%] sm:w-[100%] h-[90%] sm:mt-0 rounded-xl aspect-auto' /> */}
              <div className='flex flex-row'>
                {/* <img src={SampleWallBoxColorImage} className='w-[70%] sm:w-[45%] h-[80%] sm:mt-0 rounded-xl aspect-auto translate-x-[150px]' /> */}
                {/* <img src={SampleWallBoxImage} className='w-[70%] sm:w-[45%] h-[80%] sm:mt-0 rounded-xl aspect-auto' /> */}
              </div>
            </div>
          </div>
        </div>
        {/* <img src={ConstruxComponentsImage} className='w-[80%] md:w-[50%] mt-10 sm:mt-0 rounded-xl mx-auto' /> */}
      </div>

    </div>
  )
}

export default ComponentFeatures
