// src/components/TermsOfService.js
import React from 'react';
import { Helmet } from "react-helmet";

const TermsOfService = () => {
    return (
        <div className="container mx-auto p-10 sm:p-20">
            <Helmet>
                <title>Terms of Service</title>
            </Helmet>
            <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
            <p>
                These Terms of Service ("Terms") govern your access to and use of Build Construx, Inc.'s website and services (the "Service"). By using our Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">1. Acceptance of Terms</h2>
            <p>
                By accessing or using our Service, you confirm that you have read, understood, and agreed to these Terms. We may update the Terms from time to time, and you are responsible for checking them periodically for changes.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">2. Use of the Service</h2>
            <p>
                You agree to use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service in any way that could damage, disable, overburden, or impair our servers or networks.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">3. Intellectual Property</h2>
            <p>
                All content, features, and functionality of the Service are owned by Build Construx, Inc., and are protected by copyright, trademark, and other intellectual property laws. You may not use or copy any of our content without express permission.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">4. Limitation of Liability</h2>
            <p>
                Build Construx, Inc. will not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Service.
            </p>

            <h2 className="text-2xl font-bold mt-6 mb-4">5. Governing Law</h2>
            <p>
                These Terms are governed by the laws of the jurisdiction in which Build Construx , Inc. operates. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in that jurisdiction.
            </p>

            <p className="mt-6">If you have any questions about these Terms, please contact us at legal@buildconstrux.com.</p>
        </div>
    );
};

export default TermsOfService;
