import React from 'react';
import { FaShower } from "react-icons/fa6";
import { FaBed } from "react-icons/fa6";
import { useContentful } from '../contentful-context';
import { getImageUrl } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";


function ModelFeatures({ features }) {

    features = features.split(',')
    return (
        <div className='grid grid-cols-3 divide-x-[1px] text-black pt-5 sm:px-10'>
            <div className='text-center flex flex-col items-center sm:w-[100px]'>
                <div className='flex flex-row items-center'>
                    <FaBed size={20} color='black' />
                    <div className='text-xs md:text-lg pl-2 font-bold'>{features[0]}</div>
                </div>
                <p className='text-xs md:text-md font-semibold'>
                    Bedrooms
                </p>
            </div>
            <div className='text-center flex flex-col items-center'>
                <div className='flex flex-row items-center'>
                    <FaShower size={20} color='black' />
                    <div className='text-xs md:text-lg pl-2 font-bold'>{features[1]}</div>
                </div>
                <p className='text-xs md:text-md font-semibold'>
                    Bathrooms
                </p>
            </div>
            <div className='text-center flex flex-col items-center'>
                <div className='flex flex-row items-center  md:pb-0'>
                    <div className='text-xs md:text-lg font-bold'>{features[2]}</div>
                </div>
                <p className='text-xs md:text-md font-semibold'>
                    Sq. Ft.
                </p>
            </div>
        </div>
    )
}

function ModelCard({ model }) {
    const { name, description, features } = model;
    const imageUrl = getImageUrl(model);
    const navigate = useNavigate();

    return (
        <div className='rounded w-[350px] flex flex-col bg-white justify-start opacity-90 hover:opacity-100 cursor-pointer' onClick={() => navigate(`/models/${name.toLowerCase()}`, { state: { model: name.toLowerCase() } })}>
            <p className='text-black text-center text-xl font-semibold p-2 sm:visible'>{name}</p>
            <img src={imageUrl} className='h-[90%] w-[80%] mx-auto sm:px-5 sm:w-[80%]' />
            <ModelFeatures features={features[0]} />
            <button className='bg-white sm:bg-black self-center outline text-black  sm:text-white rounded w-[65%] font-medium my-6 md:mx-0 py-3'>View {name}</button>
        </div>
    )
}
export default function Models() {
    const content = useContentful();

    if (!content.models.length) {
        return null;
    }
    return (
        <div className='flex flex-wrap px-20 font-bai justify-center'>
            <Helmet>
                <title>Models</title>
            </Helmet>
            {content.models.sort((a, b) => a.name.localeCompare(b.name)).map((model, i) => <ModelCard key={i} model={model} />)}
        </div>
    )
}

export { ModelFeatures };