import React, { useEffect, useRef } from 'react'
import { Viewer as SpeckleViewer, DefaultViewerParams, SpeckleLoader } from '@speckle/viewer';
import { CameraController, SelectionExtension } from '@speckle/viewer';


function Viewer() {

    const setupViewer = async () => {
        const canvas = document.getElementById("canvas")


        const params = DefaultViewerParams;
        params.showStats = false;
        params.verbose = true;

        const viewer = new SpeckleViewer(canvas, params);
        await viewer.init();
        // viewer.createExtension(SelectionExtension);
        viewer.createExtension(CameraController);
        const loader = new SpeckleLoader(
            viewer.getWorldTree(),
            "https://speckle.xyz/streams/f0cfb817d1/objects/5aa3248122308e715e3bda8509798f48",
            ""
        );

        await viewer.loadObject(loader, 1, true);
    }

    useEffect(() => {
        setupViewer();
    }, []);


    return (
        <div className='w-full my-5 rounded-xl'>
            <div className='h-[600px] bg-white rounded-xl' id='canvas'>
            </div>
        </div>
    )
}

export default Viewer
