import React from 'react';
import Animate from './Animate';
import StoryText from './StoryText';


export default function StoryBlock({ title, subtitle, body, imgUrl }) {
  return (
    <div>
        <div className='bg-white w-full mx-auto text-start flex flex-col justify-center pb-5 pt-5 gap-3'>
            <Animate>
                {title && (<h1 className={`text-start md:text-4xl text-xl font-semibold md:text-center`}>{title}</h1>)}
            </Animate>
            <Animate>
                {subtitle && (<p className={`text-start md:text-3xl text-xl text-[#a43424] md:text-center`}>{subtitle}</p>)}
            </Animate>
            <h2 
            className={`md:py-6 text-gray-600 ${title ? 'md:text-xl text-base' : 'md:text-4xl sm:text-2xl text-2xl'}`}
            >
                {body}
            </h2>
        </div>
        {/* <div style={{backgroundImage: `url(${imgUrl})`}} className='h-[300px] sm:h-[400px] md:h-[600px] bg-center bg-cover duration-100'  /> */}
    </div>
  )
}

