import React from 'react'
import WIKIHOUSELOGO from '../assets/wikihouse-logo-black.png';
import PELLALOGO from '../assets/pella-logo-yellow.png';
import RDDLOGO from '../assets/rdd-logo-transparent.png';
import HUBERLOGO from '../assets/huber-logo-transparent.png';
import SCRALOGO from '../assets/scra-logo.png';
import SHOPBOTLOGO from '../assets/shopbot-logo.png';
import DIAMONDKOTELOGO from '../assets/dk-logo-black.png';
import CFLLOGO from '../assets/charleston-fablab-logo.png';

function Partners() {

  const openLink = (url) => {
    window.open(url, '_blank');
  } 

  return (
    <div className='w-full px-2 bg-white mt-20 mb-20'>
      <h1 className='text-center text-4xl py-4 pb-10 font-bold'>Our Partners & Supporters</h1>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-10 justify-center py-5 items-center'>
        <div className='mx-auto cursor-pointer' onClick={() => openLink('https://www.wikihouse.cc/')}>
            <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/wikihouse-logo-black.png'} alt='wikihouse' className='w-32' />
        </div>
        <div className='mx-auto cursor-pointer' onClick={() => openLink('https://www.pella.com/')}>
            <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/pella-logo-yellow.png'} alt='pella' className='w-32' />
        </div>
        <div className='mx-auto cursor-pointer' onClick={() => openLink('https://www.huberwood.com/')}>
            <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/huber-logo-transparent.png'} alt='huber' className='w-32'/>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-10 justify-center py-5 items-center'>
        <div className='mx-auto cursor-pointer' onClick={() => openLink('https://www.scra.org/')}>
                <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/scra-logo.png'} alt='scra' className='w-32' />
            </div>
            <div className='mx-auto cursor-pointer' onClick={() => openLink('https://www.shopbottools.com/')}>
                <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/shopbot-logo.png'} alt='shopbot' className='w-32 bg-black'  />
            </div>
            <div className='mx-auto cursor-pointer' onClick={() => openLink('https://diamondkotesiding.com/')}>
                <img src={'https://dgiy9ku8pat3z.cloudfront.net/images/dk-logo-black.png'} alt='diamondkote' className='w-32' />
            </div>
      </div>
    </div>
  )
}

export default Partners
