import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Get the current path
    const currentPath = location.pathname + location.search;

    // Track a new page using setPath and trackPageView
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', currentPath]); // Set the path
    _hsq.push(['trackPageView']); // Track the page view

    // Optionally, update the browser state (use this if you want to manipulate browser history)
    // var stateObj = { foo: 'updated' };
    // history.pushState(stateObj, "updated page", currentPath); // Update the browser state if needed
  }, [location]);

  return null; // This component does not render anything
};

export default PageTracker;
