import React from 'react';
import Interior from '../assets/interior.jpg'
import { useContentful } from './contentful-context';
import MavenImage from '../assets/maven-inside-view.jpg';

function ImageBlock({ imageUrl }) {
  const content = useContentful();

  if(!content.images.length){
    return null;
  }

  const homeInterior = content.images.find(item => item.id == 'home-interior');

  const { id, alt, image } = homeInterior;
  const { file } = image.fields;
  
  return (
    <div className='bg-white'>
        <img src={MavenImage} alt={alt} className='relative w-[100%] mx-auto overflow-hidden'/>
    </div>
  )
}

export default ImageBlock
