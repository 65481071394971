import React from 'react'
import Animate from './Animate';

function StoryBlockBackground({ title, subtitle, body, imgUrl, textColor, children }) {
  return (
    <div style={{backgroundImage: `url(${imgUrl})`}} className='h-[600px] bg-center bg-cover duration-100 w-full mx-auto text-start flex flex-col justify-center py-10 px-6 gap-3 m-3'>
                {title && (<h1 className={`md:text-4xl text-2xl font-semibold max-w-[300px] md:max-w-[500px] text-start ${textColor && textColor}`}>{title}</h1>)}
                {subtitle && (<p className={`md:text-3xl text-2xl ${textColor ? textColor : 'text-[#a43424]'}  max-w-[300px] md:max-w-[500px] text-start`}>{subtitle}</p>)}
            <h2 
            className={`md:py-6 text-white max-w-[320px] md:max-w-[800px] ${title ? 'md:text-lg text-lg' : 'md:text-4xl sm:text-2xl text-2xl'}`}
            >
                {body}
            </h2>
            <div>
                {children}
            </div>
    </div>
  )
}

export default StoryBlockBackground
