import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../../assets/logo.png' 
import { useContentful } from '../contentful-context';

function Navbar() {
  const content = useContentful();
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  }

  const closeNav = () => {
    setNav(true);
  }

  if(!content.images.length){
    return null;
  }

  const { image } = content.images.find(image => image.id == "Construx-Black-FullColor-Tag");
  const { file } = image.fields;

  return (
    <>
      <nav className='text-black flex items-center h-24 w-full mx-auto px-4 justify-between bg-white'>
        <Link to='/'>
          <img src={file.url} alt='construx logo' className='w-32 md:w-60' />
        </Link>
        <ul className='hidden md:flex'>
          {/* <Link to="/">
            <li className='p-4 hover:scale-105'>Home</li>
          </Link> */}
          <Link to='/story'>
            <li className='p-4 hover:scale-105'>Our Story</li>
          </Link>
          <Link to='/product'>
            <li className='p-4 hover:scale-105'>Product</li>
          </Link>
          {/* <Link to='/how'>
            <li className='p-4 hover:scale-105'>How it Works</li>
          </Link> */}
          <Link to='/models'>
            <li className='p-4 hover:scale-105'>Models</li>
          </Link>
          <Link to='/faqs'>
            <li className='p-4 hover:scale-105'>FAQ</li>
          </Link>
          <Link to='/contact'>
            <li className='p-4 hover:scale-105'>Contact</li>
          </Link>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
          {!nav ? 
          <AiOutlineClose size={20} /> :
          <AiOutlineMenu size={20} />
          }
        </div>
        <div className={!nav ? 'fixed left-0 top-0 w-[60%] border-r border-r-gray-200 bg-white h-full ease-in-out duration-500 z-40 text-black' : 'h-full fixed left-[-100%] ease-in-out duration-500'}>
          {/* <Link to='/' onClick={closeNav}>
            <img src={Logo} alt='construx logo' className='w-32 px-4 py-6' />
          </Link> */}
          <ul className='p-4'>
            <Link to='/' onClick={closeNav}>
              <li className='p-4 border-b border-gray-600'>Home</li>
            </Link>
            <Link to='/story' onClick={closeNav}>
              <li className='p-4 border-b border-gray-600'>Our Story</li>
            </Link>
            <Link to='/product' onClick={closeNav}>
              <li className='p-4 border-b border-gray-600'>Product</li>
            </Link>
            {/* <Link to='/how' onClick={closeNav}>
              <li className='p-4 border-b border-gray-600'>How it Works</li>
            </Link> */}
            <Link to='/models' onClick={closeNav}>
              <li className='p-4 border-b border-gray-600'>Models</li>
            </Link>
            <Link to='/faqs' onClick={closeNav}>
              <li className='p-4 border-b border-gray-600'>FAQ</li>
            </Link>
            <Link to='/contact' onClick={closeNav}>
              <li className='p-4 border-b border-gray-600'>Contact</li>
            </Link>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar
