function getImageUrl(content){
    const { image } = content;
    const { file } = image.fields;

    return file.url;
}

function getImageUrlByName(images, name){
    const content = images.find(img => img.id == name);
    return getImageUrl(content);
}


function getImageUrls(content){
    const { images } = content;
    let imageUrls = [];
    for (let i = 0; i < images.length; i++) {
        const { file } = images[i];
        imageUrls.push(file.url)
    }

    return imageUrls;
}

export { getImageUrl, getImageUrlByName }
