import React, { useState, useEffect, useRef } from 'react';
import * as FaIcons from 'react-icons/fa'; 
import { useContentful } from '../contentful-context'; 
import { Helmet } from "react-helmet";


const ProcessPhases = () => {
  const { phases } = useContentful();
  const [activeIndex, setActiveIndex] = useState(null);
  const sectionRefs = useRef([]);

  if (!phases || !phases.length) {
    return null; 
  }

  function sortPhasesByNumber(phases) {
    return phases.sort((a, b) => {
      const getPhaseNumber = (title) => {
        const match = title.match(/Phase (\d+):/);
        return match ? parseInt(match[1], 10) : 0;
      };
  
      return getPhaseNumber(a.title) - getPhaseNumber(b.title);
    });
  }

  var sortedPhases = sortPhasesByNumber(phases);

  return (
    <div className='bg-white px-20 md:px-20 font-bai'>
       <Helmet>
        <title>How It Works</title>
      </Helmet>
      {sortedPhases.map((phase, index) => (
        <div
          key={index}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center mt-16 p-10 border-solid border-black border-b"
          ref={(el) => (sectionRefs.current[index] = el)}
        >
          <h2 className={`text-3xl font-extrabold ${activeIndex === index ? 'text-blue-600' : 'text-gray-900'}`}>
            {phase.title}
          </h2>
          <p className={`mt-4 text-lg ${activeIndex === index ? 'text-blue-600' : 'text-gray-500'}`}>
            {phase.description}
          </p>
          <div className={`grid grid-cols-1 ${phase.steps.length > 3 ? 'md:grid-cols-4' : 'md:grid-cols-3'} gap-8 mt-8`}>
            {phase.steps.map((step, stepIndex) => {
              const IconComponent = FaIcons[step.fields.icon] || FaIcons.FaCheckCircle;
              return (
                <div
                  key={stepIndex}
                  className={`flex flex-col items-center align-baseline justify-evenly mb-20 mt-10`}
                >
                  <IconComponent className={`text-5xl ${activeIndex === index ? 'text-blue-600' : 'text-black-600'} w-[60px] h-[60px]`} />
                  <h3 className={`mt-4 text-lg font-medium ${activeIndex === index ? 'text-blue-600' : 'text-gray-900'}`}>
                    {step.fields.title}
                  </h3>
                  <p className={`mt-2 ${activeIndex === index ? 'text-blue-600' : 'text-gray-500'}`}>
                    {step.fields.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProcessPhases;
