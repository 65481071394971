import React from 'react';
import { ReactTyped } from "react-typed";
import Video from '../../assets/truss_install.mov';
import { useNavigate } from "react-router-dom";
import { useContentful } from '../contentful-context';

import ConstruxLogo from '../../assets/construx-logo.png';

function Hero({ video }) {
  const navigate = useNavigate();
  const content = useContentful();

  if (!content.hero.length) {
    return null;
  }

  const heroContent = content.hero[0];

  const { header, subtitle, imageUrl, list } = heroContent;

  function handleClick(location) {
    navigate(location)
  }

  return (
    <header className="relative flex items-center justify-center h-[600px] md:h-screen overflow-hidden">
      <div
        className="relative z-20 p- text-2xl text-white bg-opacity-50 rounded-xl flex flex-col text-center justify-center"
      >
        {/* <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold  text-[#fdfdfd]'>Build Construx</h1> */}
        <img src={ConstruxLogo} className='px-20 sm:w-[35rem]' />
        <h2 className='md:text-7xl sm:text-6xl text-2xl font-semibold text-[#fdfdfd]'>{header}</h2>
        <div>
          <p className='md:text-3xl sm:text-4xl text-lg mx-auto w-[400px] md:w-full font-bold mt-2 text-white'>{subtitle}</p>
          {/* <ReactTyped
            className='text-white font-bold text-base'
            strings={[
              ...list
            ]}
            typeSpeed={120}
            backSpeed={140}
            loop
          /> */}
        </div>
        <button onClick={() => handleClick('/product')}  className='bg-[#a43424] w-[200px] rounded font-medium my-6 mx-auto py-3 text-white text-sm sm:text-base'>Learn More</button>
      </div>
      <video className="absolute z-10 w-auto min-w-full min-h-full max-w-none" loop muted autoPlay playsInline>
        <source src={"https://dgiy9ku8pat3z.cloudfront.net/videos/truss_install.mov"} type='video/mp4'>
        </source>
      </video>
    </header>
  )
}

export default Hero
