import React from 'react';
import { Link } from 'react-router-dom';
import {
    FaInstagramSquare,
    FaLinkedinIn
} from 'react-icons/fa';
import Logo from '../../assets/logo.png' 
import { useContentful } from '../contentful-context';
import { getImageUrl } from '../utils';

function Footer() {
  const content = useContentful();

  if (!content.footer.length || !content.images.length) {
    return null;
  }

  const construxLogoWhite = getImageUrl(content.images.find(image => image.id === "Construx-White-FullColor-Tag"));

  const openLink = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div className='mx-auto py-4 px-10 text-white bg-black w-full'>
      <div className='flex flex-row items-center justify-between'>
        <div className='flex flex-row items-center'>
          {/* Optional: Add the logo back if necessary */}
          {/* <Link to='/'>
            <img src={construxLogoWhite} alt='construx logo' className='w-60' />
          </Link> */}
          <p className='text-xs'>
            © 2024 Build Construx, Inc.
             {/* | 
            <Link to="/terms" className="ml-1">Terms of Service</Link> | 
            <Link to="/privacy" className="ml-1">Privacy Policy</Link> */}
          </p>
        </div>
        
        <div className='flex flex-row'>
          <FaInstagramSquare className='p-1' size={30} onClick={() => openLink('https://www.instagram.com/buildconstrux/')} />
          <FaLinkedinIn className='p-1' size={30} onClick={() => openLink('https://www.linkedin.com/company/buildconstrux')} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
