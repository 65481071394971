import React from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import TextBlock from '../TextBlock';
import StoryBlock from '../StoryBlock';
import StoryBlockVariant from '../StoryBlockVariant';
import StoryBlockBackground from '../StoryBlockBackground';
import StoryText from '../StoryText';
import AssemblyAnimation from '../../assets/assembly-video.mp4'
import { useContentful } from '../contentful-context';
import { Helmet } from "react-helmet";


import ShopDemoImage from '../../assets/shop-demo.jpg';
import ConstruxFrameImage from '../../assets/construx-frame.jpg';
import ConstruxProcessImage from '../../assets/construx-process.jpg';
import ConstruxModelingImage from '../../assets/construx-modeling.png';

const YT = () => {
  var player;
  function onYouTubePlayerAPIReady() {
    player = new YT.Player('ytplayer', {
      height: '360',
      width: '640',
      videoId: 'M7lc1UVf-VE'
    });
  }

  var tag = document.createElement("script");
  tag.arc = "https://www.youtube.com/embed/ZiUB_Chdseo?si=SplOPcspniLZybjq??playsinline=1&iv_load_policy=3&rel=0&showinfo=0&controls=1&fs=0&start=0&autoplay=1&enablejsapi=1&widgetid=1"
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


  return (<div></div>)
  
}

function Story() {
  const content = useContentful();

  if (!content.story.length) {
    return null;
  }

  const first = content.story.find(item => item.sectionId.indexOf('1') != -1);
  const second = content.story.find(item => item.sectionId.indexOf('2') != -1);
  const third = content.story.find(item => item.sectionId.indexOf('3') != -1);
  const fourth = content.story.find(item => item.sectionId.indexOf('4') != -1);
  const fifth = content.story.find(item => item.sectionId.indexOf('5') != -1);
  const sixth = content.story.find(item => item.sectionId.indexOf('6') != -1);

  return (
    <div className='bg-white px-10 md:px-20 font-bai'>
       <Helmet>
        <title>Story</title>
      </Helmet>
      <iframe className='mx-auto top-0 left-0 h-full w-full sm:h-[800px] overflow-hidden' width="1200" height="800" src="https://www.youtube.com/embed/ZiUB_Chdseo?si=SplOPcspniLZybjq??playsinline=1&iv_load_policy=3&rel=0&showinfo=0&controls=1&fs=0&start=0&autoplay=1&enablejsapi=1&widgetid=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>      {/* <VideoPlayer Video={'https://dgiy9ku8pat3z.cloudfront.net/videos/maven-timelapse.MOV'} /> */}
      <StoryText 
       title={first.header}
       body={first.section}
      />
      
      <StoryBlock
        title={second.header}
        subtitle={second.subtitle}
        body={second.section}
        // imgUrl={second.image.fields.file.url}
      />
      <img src={ConstruxModelingImage} className='w-full mx-auto'  />

      <StoryBlock
        title={third.header}
        subtitle={third.subtitle}
        body={third.section}
        // imgUrl={third.image.fields.file.url}
      />
      <img src={ConstruxProcessImage} className='w-full mx-auto'  />

      <StoryBlock
        title={fourth.header}
        subtitle={fourth.subtitle}
        body={fourth.section}
        // imgUrl={fourth.image.fields.file.url}
      />
      <img src={ConstruxFrameImage} className='w-full mx-auto'  />

      <StoryBlock 
       title={fifth.header}
       subtitle={fifth.subtitle}
       body={fifth.section}
      />
        <img src={ShopDemoImage} className='w-full mx-auto'  />

      {/* <StoryBlockVariant /> */}
      <StoryBlock 
        title={sixth.header}
        subtitle={sixth.subtitle}
        body={sixth.section}
      />
      {/* <StoryBlockBackground
        imgUrl={sixth.imageUrl}
      /> */}
    </div>
  )
}

export default Story
